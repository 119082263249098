<template>
    <OOrgunitLookup
        :bind="publishToOrgUnit">
        <template #orgunit>
            <button type="button" class="btn btn-link p-0 mt-2 text-decoration-none">
                <h6 class="d-inline text-primary" style="cursor:pointer"><i class="bi bi-plus-lg"></i> {{$t('Publish To Org Units')}}</h6>
            </button>
        </template>
    </OOrgunitLookup>
    <ODataGrid
        :dataObject="local_dsDocPublToOrgUnits"
        hideMultiselectColumn
        hideGridMenu
        hideSystemColumn
        ref="ref_dsDocumentOrgUnitsP">
            <OColumn field="UnitType" width="200"/>
            <OColumn field="OrgUnit" width="200" flexWidth="100"/>
    </ODataGrid>
</template>

<script setup>
import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts'
import OOrgunitLookup from 'o365.vue.components.OrgUnitsLookup.vue';

const props = defineProps({
    dataObject: Object
});

const dsDocPublToOrgUnitsDef = {
    id: 'local_dsDocPublToOrgUnits',
    viewName: 'aviw_ECM_DocumentsPublishedTo',
    appId: props.dataObject.appId,
    uniqueTable:"atbv_ECM_DocumentsPublishedTo",
    allowDelete: true,
    allowUpdate:true,
    allowInsert:true,
    selectFirstRowOnLoad: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Document_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [
            {name: "OrgUnit_ID", type: "number" },
            {name: "OrgUnit", type: "string" },
            {name: "Responsible", type: "string" },
            {name: "UnitType", type: "string" },
            {name: "OrgUnitTitle", type: "string" },
        ]
}

const local_dsDocPublToOrgUnits = getOrCreateDataObject(dsDocPublToOrgUnitsDef);

const publishToOrgUnit = (sel) => {
        local_dsDocPublToOrgUnits.createNew({Document_ID:props.dataObject.current.ID,OrgUnit_ID:sel.ID});
        local_dsDocPublToOrgUnits.save().then(() => local_dsDocPublToOrgUnits.load());
}
</script>